<template>
  <v-icon color="success" v-if="enabled">fas fa-check</v-icon>
  <v-icon color="error" v-else>fas fa-times-circle</v-icon>
</template>

<script>
export default {
  name: "IconCheckOrTimes",

  props: {
    enabled: {type: Boolean, default: false}
  }
}
</script>

<style scoped>

</style>